<template>
  <div>
    <div class="row pb-2">
      <div class="col-sm my-auto">
        <span class="font-weight-bold">Create new AOI</span>
      </div>
      <div class="col-sm text-right">
        <b-button size="sm" @click="createAOIContainerOpened = !createAOIContainerOpened" variant="secondary"
          class="mb-0">
          <b-icon :icon="createAOIToggleIcon"></b-icon>
        </b-button>
      </div>
    </div>

    <b-collapse v-model="createAOIContainerOpened">
      <b-overlay :show="createAOIOverlay == true" rounded="sm">
        <b-card>
          <div v-if="createStep == 'step_1'">
            <b-form-group id="input-group-1" label="Name" class="form-label-bold" autocomplete="off">
              <b-form-input v-model="aoiForm.name" placeholder="AOI name ..." autocomplete="off"
                @focus="aoiCreateInputFocused" maxlength="40" data-cy="input-aoi"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-2" label="Description" label-for="input-2" class="form-label-bold">
              <b-form-textarea id="textarea-aoi-description" placeholder="AOI description ..."
                v-model="aoiForm.description" rows="3" no-resize @focus="aoiCreateInputFocused"
                data-cy="input-aoi-description">
              </b-form-textarea>
            </b-form-group>
            <div class="mt-3">
              <b-button block variant="primary" :disabled="saveAOIStep1ButtonBusy" @click="stepOneSubmit"
                data-cy="submit-next-step">
                Next step
              </b-button>
            </div>
          </div>

          <div v-else-if="createStep == 'step_2'">
            <div>
              <b-tabs content-class="mt-3" v-model="tabIndex" small>
                <b-tab title="AOI Coordinates" active>
                  <small><i>Please enter the coordinates of the AOI in WGS 84 (EPSG: 4326)</i></small><br />
                  <!-- <div class="row">
                  <div class="col col-9">
                    <small><i>Please enter the coordinates of the AOI in WGS 84</i></small><br/>
                    <small><i>(EPSG: 4326).</i></small>
                  </div>
                  <div class="col col-3 text-right">
                  <b-button size="sm" variant="secondary" class="mb-0 mr-1">
                    <b-icon icon="pencil-square"></b-icon>
                  </b-button>
                  </div>
                </div> -->

                  <b-form-group id="input-group-3" label="Upper-Left" class="form-label-bold mt-2">
                    <div class="row">
                      <div class="col">
                        <b-form-group valid-feedback="Latitude value valid" invalid-feedback="Latitude value invalid"
                          :state="validateBoundingBoxInputs('bbSWLat')" class="font-weight-validation">
                          <b-form-input v-model="aoiForm.bbSWLat" placeholder="Latitude ..." size="sm" autocomplete="off"
                            type="number" step="0.1"></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group valid-feedback="Longitude value valid" invalid-feedback="Longitude value invalid"
                          :state="validateBoundingBoxInputs('bbSWLon')" class="font-weight-validation">
                          <b-form-input v-model="aoiForm.bbSWLon" placeholder="Longitude ..." size="sm" autocomplete="off"
                            type="number" step="0.1"></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </b-form-group>

                  <b-form-group id="input-group-3" label="Lower-Right" class="form-label-bold mb-0">
                    <div class="row">
                      <div class="col">
                        <b-form-group valid-feedback="Latitude value valid" invalid-feedback="Latitude value invalid"
                          :state="validateBoundingBoxInputs('bbNELat')" class="font-weight-validation">
                          <b-form-input v-model="aoiForm.bbNELat" placeholder="Latitude ..." autocomplete="off" size="sm"
                            type="number" step="0.1"></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col">
                        <b-form-group valid-feedback="Longitude value valid" invalid-feedback="Longitude value invalid"
                          :state="validateBoundingBoxInputs('bbNELon')" class="font-weight-validation">
                          <b-form-input v-model="aoiForm.bbNELon" placeholder="Longitude ..." autocomplete="off" size="sm"
                            type="number" step="0.1"></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                  </b-form-group>

                  <div class="text-right mb-2">
                    <b-link v-bind:class="{ 'disabled-link': insertLinkActiv }" v-on:click="insertFromClipboard">Insert
                      from Clipboard</b-link> <br />
                    <b-link v-on:click="clearCoordinates">Clear all</b-link>
                  </div>

                  <b-button class="mb-3" block size="sm" variant="secondary" :disabled="!aoiCoordinatesValid"
                    @click="drawAOIClicked">Draw AOI
                  </b-button>
                </b-tab>
                <b-tab title="Draw AOI">
                  <small><i>Please draw an AOI on the map.</i></small>
                </b-tab>
                <b-tab title="Regions" id="tab-regions">
                  <small>
                    <i>
                      Please select an administrative region from the list.
                      <b-icon id="disclaimer-info-btn" @click="showDisclaimerModal" class="ml-1" icon="info-circle"
                        style="color:#007bff"></b-icon>
                    </i>
                  </small>
                  <div class="mb-2"></div>
                  <div id="list-select-country">
                    <model-list-select :list="countryCodeList" @select="onSelectContry" option-value="code"
                      option-text="name" placeholder="Select country ..." v-model="aoiForm.selectedCountryCode"
                      data-cy="select-country">
                    </model-list-select>
                    <div class="mt-1 mb-2"></div>
                    <model-list-select :list="regionsList" @select="onSelectRegion" v-model="aoiForm.selectedRegion"
                      option-value="code" option-text="name" placeholder="Select region ..."
                      :isDisabled="(Object.keys(aoiForm.selectedCountryCode).length === 0)" data-cy="select-region">
                    </model-list-select>
                  </div>
                </b-tab>
              </b-tabs>
            </div>

            <div v-if="tabIndex == 0" class="mt-2 mb-1">
              <div class="form-label-bold">Geometry</div>
              <b-icon v-if="addedAOICount == 0" icon="exclamation-triangle-fill" scale="1.2" variant="warning"></b-icon>
              <b-icon v-else-if="addedAOICount == 1" icon="check-square-fill" scale="1.2" variant="success"></b-icon>
              <b-icon v-else-if="addedAOICount >= 1" icon="exclamation-triangle-fill" scale="1.2"
                variant="warning"></b-icon>
              <label v-if="addedAOICount == 0" class="aoi-info-label">No AOI added!</label>
              <label v-else-if="addedAOICount == 1" class="aoi-info-label">AOI added!</label>
              <label v-else-if="addedAOICount >= 1" class="aoi-info-label">Please add only one AOI!&nbsp; (Currently:
                {{ addedAOICount }} AOIs)</label>
            </div>

            <div v-if="tabIndex == 1" class="mt-2 mb-1">
              <div class="form-label-bold mb-2">Geometry</div>
              <b-icon v-if="aoiCount == 0" icon="exclamation-triangle-fill" scale="1.2" variant="warning"></b-icon>
              <b-icon v-else-if="aoiCount == 1" icon="check-square-fill" scale="1.2" variant="success"></b-icon>
              <b-icon v-else-if="aoiCount >= 1" icon="exclamation-triangle-fill" scale="1.2" variant="warning"></b-icon>
              <label v-if="aoiCount == 0" class="aoi-info-label">No AOI drawn!</label>
              <label v-else-if="aoiCount == 1" class="aoi-info-label">AOI drawn!</label>
              <label v-else-if="aoiCount >= 1" class="aoi-info-label">Please draw only one AOI!&nbsp; (Currently:
                {{ aoiCount }} AOIs)</label>
            </div>

            <div v-if="(tabIndex == 2)" class="mt-2 mb-1">
              <div class="form-label-bold">Geometry</div>
              <b-icon v-if="addedAOICount == 0" icon="exclamation-triangle-fill" scale="1.2" variant="warning"></b-icon>
              <b-icon v-else-if="addedAOICount == 1" icon="check-square-fill" scale="1.2" variant="success"></b-icon>
              <label v-if="addedAOICount == 0" class="aoi-info-label">No region selected!</label>
              <label v-else-if="addedAOICount == 1" class="aoi-info-label">Region selected!</label>
            </div>

            <div class="mt-2">
              <div v-if="aoiSizeWarning">
                <b-alert show variant="warning" dismissible @dismissed="alertClosedClicked()">
                  <p>The area of the AOI is too large! Please change the size and try it again.</p>
                  <p class="mb-0">Maximum area allowed: {{ getAreaInKM2(this.aoiSizeMaxStr) }} &#13218;</p>
                  <p class="mb-1">Area of current AOI: {{ getAreaInKM2(this.aoiSizeCurrentAOI) }} &#13218;</p>
                </b-alert>
              </div>
              <b-button block variant="primary" :disabled="saveAOIStep2ButtonBusy" @click="onSubmit"
                data-cy="submit-save-aoi">Save AOI</b-button>
              <b-button block variant="secondary" @click="stepCancelClicked">Cancel</b-button>
            </div>
          </div>

          <div style="text-align:center;margin-top:12px;">
            <span class="step"
              v-bind:class="{ active: createStepActiveArr[0] == true, finish: createStepFinishedArr[0] == true }"></span>
            <span class="step"
              v-bind:class="{ active: createStepActiveArr[1] == true, finish: createStepFinishedArr[1] == true }"></span>
          </div>
        </b-card>
      </b-overlay>
    </b-collapse>
  </div>
</template>

<script>
import axios_services from '@/axios/axios-services.js'
import { eventBus } from "@/main.js";
import L from "leaflet";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "CreateAOI",
  components: {
    ModelListSelect
  },
  data() {
    return {
      tabIndex: null,
      createStepActiveArr: [true, false],
      createStepFinishedArr: [false, false],
      createStep: 'step_1',
      createAOIContainerOpened: true,
      createAOIButtonBusy: false,
      createAOISpinner: false,
      createAOIOverlay: false,
      aoiForm: {
        name: "",
        description: "",
        bbSWLat: "",
        bbSWLon: "",
        bbNELat: "",
        bbNELon: "",
        selectedCountryCode: {},
        selectedRegion: {}
      },
      latLongValidation: {
        bbSWLat: null,
        bbSWLon: null,
        bbNELat: null,
        bbNELon: null
      },
      addedAOICount: 0,
      aoiSizeWarning: false,
      aoiSizeMax: 1000000000000,
      aoiSizeMaxStr: 1000000000000,
      aoiSizeCurrentAOI: 0,
      countryCodeList: [],
      regionsList: [],
      selectedCountryCode: {},
    };
  },
  computed: {
    createAOIToggleIcon() {
      // return this.createAOIContainerOpened ? "caret-up" : "caret-down"; // old icon
      return this.createAOIContainerOpened ? "chevron-up" : "chevron-down";
    },
    saveAOIStep1ButtonBusy() {
      if (
        this.aoiForm.name.length > 0 &&
        this.aoiForm.description.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    },
    aoiCount() {
      return this.$store.getters.getDrawnAOIsCount;
    },
    saveAOIStep2ButtonBusy() {
      return ((this.aoiCount == 1) || (this.addedAOICount == 1)) ? false : true;
    },
    aoiCoordinatesValid() {
      if (
        this.latLongValidation["bbSWLat"] == true &&
        this.latLongValidation["bbSWLon"] == true &&
        this.latLongValidation["bbNELat"] == true &&
        this.latLongValidation["bbNELon"] == true
      ) {
        return true;
      } else {
        return false;
      }
    },
    aoiSelectionChanged() {
      return this.$store.getters.getAOIsActiveItem;
    },
    getClipboardCoordinates() {
      return this.$store.getters.getClipboardCoordinates;
    },
    insertLinkActiv() {
      return (this.getClipboardCoordinates == null) ? true : false;
    },
    clientID() {
      return this.$store.getters.clientID;
    },
    administrativeRegions() {
      return this.$store.getters.getAdministrativeRegions;
    }
  },
  mounted() {
    this.$store.dispatch('loadAdministrativeRegions').then(() => {
      this.initSelectAOILists();
    })
  },
  methods: {
    stepOneSubmit() {
      this.createStep = 'step_2';
      this.createStepActiveArr[0] = false;
      this.createStepActiveArr[1] = true;
      this.createStepFinishedArr[0] = true;
    },
    stepCancelClicked() {
      this.createStep = 'step_1';
      this.createStepActiveArr[0] = true;
      this.createStepActiveArr[1] = false;
      this.createStepFinishedArr[0] = false;
      this.resetCreateAOIBox();
    },
    drawAOIClicked() {
      var aoiValid;
      aoiValid = true;

      var bounds = [
        [this.aoiForm["bbSWLat"], this.aoiForm["bbSWLon"]], // represents the upper-left coordinate
        [this.aoiForm["bbNELat"], this.aoiForm["bbNELon"]]  // represents the lower-right coordinate
      ];
      var rectangleGeoJSON = L.rectangle(bounds).toGeoJSON();

      if (aoiValid) {
        this.$store.commit("setAddedAOI", rectangleGeoJSON);
        this.addedAOICount = 1;
      } else {
        this.$store.commit("setAddedAOI", null);
        this.addedAOICount = 0;
      }
    },
    drawSelectedRegion(region_id) {
      //var coordinates;
      axios_services.get(`utils/administrative_boundaries/${region_id}`).then((response) => {
        //coordinates = response.data[0].buffered_boundary.coordinates;
        //var rectangleGeoJSON = L.rectangle(coordinates).toGeoJSON();
        this.$store.commit("setAddedAOI", response.data[0].buffered_boundary);
        this.addedAOICount = 1;
      });
    },
    onSelectContry(countryCode) {
      this.aoiForm.selectedCountryCode = countryCode;
    },
    onSelectRegion(selectedRegion) {
      this.aoiForm.selectedRegion = selectedRegion;
    },
    onSubmit() {
      this.aoiSizeWarning = false;

      var aoi;
      var aoiSizeCorrect;
      var skipAoiAreaCheck = false;
      var regionStr = "";

      if (this.tabIndex == 0) {
        aoi = this.$store.getters.getAddedAOI.geometry;
        aoiSizeCorrect = this.checkAOISize("added");
      } else if (this.tabIndex == 1) {
        aoi = this.$store.getters.getDrawnAOI.getLayers()[0].toGeoJSON().geometry;
        aoiSizeCorrect = this.checkAOISize("drawn");
      } else if (this.tabIndex == 2) {
        aoiSizeCorrect = true;
        this.aoiForm.description;
        aoi = this.$store.getters.getAddedAOI;
        skipAoiAreaCheck = true;
        regionStr = this.aoiForm.selectedCountryCode.name + "/" + this.aoiForm.selectedRegion.name;
      }

      if (!aoiSizeCorrect) {
        this.aoiSizeWarning = true;
        return;
      } else {
        this.aoiSizeWarning = false;
      }

      this.createStepActiveArr[0] = false;
      this.createStepActiveArr[1] = false;
      this.createStepFinishedArr[0] = false;
      this.createStepFinishedArr[1] = true;
      this.createAOIOverlay = true;

      var payload = {
        aoi_name: this.aoiForm.name,
        description: this.aoiForm.description,
        user_id: this.clientID,
        geoJSON: aoi,
      };

      if (regionStr != "") payload['region'] = regionStr;
      payload['skip_aoi_check'] = skipAoiAreaCheck;

      // Axios HTTP request
      var aoi_created_id;
      axios_services
        .post("aoi/create", payload)
        .then((response) => {
          this.resetCreateAOIBox();
          aoi_created_id = 'id_' + response.data.aoi_id;
          // Show notificatoin - success
          this.showNotification("success", "Success! AOI created", "Successfully created AOI");
        })
        .catch((err) => {
          console.log('Error: Cannot create AOI: ', err.response);
          //var msg = err.response.data.message.error_definition.message;
          this.showNotification("danger", "Error!", "AOI could not be created");
        })
        .then(() => {
          this.createAOIOverlay = false;
          this.$store.dispatch('getAOIs')
            .then(() => {
              this.sendNotificationAOICreated(aoi_created_id);
            });
        });
    },
    /**
     * This functions sends a notificatoin to the parent component when a AOI gets created or updated
     */
    sendNotificationAOICreated(currentAOIID) {
      this.$emit('notification_AOICreated', currentAOIID);
    },
    /**
     * This function resets the input element for creating an AOI
     */
    resetCreateAOIBox() {
      this.aoiForm.name = "";
      this.aoiForm.description = "";
      this.aoiForm.bbSWLat = "";
      this.aoiForm.bbSWLon = "";
      this.aoiForm.bbNELat = "";
      this.aoiForm.bbNELon = "";
      this.addedAOICount = 0;
      this.$store.commit("setDrawnAOIs", null);
      this.$store.commit("setAddedAOI", null);
      this.clearSelectedCountry();
      this.clearSelectedRegion();
    },
    /***
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    },
    /**
     * Eventhandler for input focused
     */
    aoiCreateInputFocused() {
      this.$store.commit('setAOIsActiveItem', null);
    },
    /***
     * This methods refreshes the AOI coordinates input fields
     */
    clearCoordinates() {
      this.aoiForm.bbSWLat = "";
      this.aoiForm.bbSWLon = "";
      this.aoiForm.bbNELat = "";
      this.aoiForm.bbNELon = "";
      this.addedAOICount = 0;
      this.$store.commit("setAddedAOI", null);
    },
    /**
     * This function inserts the coordinates which are stored in the clipboard
     */
    insertFromClipboard() {
      var bounds = this.getClipboardCoordinates;
      this.aoiForm.bbSWLat = bounds['_southWest']['lat'];
      this.aoiForm.bbSWLon = bounds['_southWest']['lng'];
      this.aoiForm.bbNELat = bounds['_northEast']['lat'];
      this.aoiForm.bbNELon = bounds['_northEast']['lng'];
    },
    /**
     * This function validates the boundinb box 
     */
    validateBoundingBoxInputs(coordinate) {
      if (this.aoiForm[coordinate] == "") {
        this.latLongValidation[coordinate] = null;
        return null;
      } else {
        var validationVal = this.validateLatLongCoordinates(coordinate, this.aoiForm[coordinate]);
        this.latLongValidation[coordinate] = validationVal;
        return validationVal;
      }
    },
    /**
     * This function validates the range of the coordinates
     */
    validateLatLongCoordinates(latLonType, latLonValue) {
      if ((latLonType == 'bbSWLat') || (latLonType == 'bbNELat')) {
        if ((latLonValue >= -90) && (latLonValue <= 90)) {
          return true;
        } else {
          return false;
        }
      } else if ((latLonType == 'bbSWLon') || (latLonType == 'bbNELon')) {
        if ((latLonValue >= -180) && (latLonValue <= 180)) {
          return true;
        } else {
          return false;
        }
      }
    },
    /**
     * This function checks if the area of the aoi is correct and returns true if the area is correct, false otherwise.
     */
    checkAOISize(aoiType) {
      var area;

      if (aoiType == 'drawn') {
        area = L.GeometryUtil.geodesicArea(this.$store.getters.getDrawnAOI.getLayers()[0].getLatLngs()[0]);
      } else if (aoiType == 'added') {
        area = L.GeometryUtil.geodesicArea(new L.geoJSON(this.$store.getters.getAddedAOI).getLayers()[0].getLatLngs()[0])
      }

      this.aoiSizeCurrentAOI = area;
      return (area < this.aoiSizeMax) ? true : false
    },
    /**
     * Eventhandler for the close button of the alert.
     */
    alertClosedClicked() {
      this.aoiSizeWarning = false;
    },
    getAreaInKM2(v) {
      const roundedAreaValue = parseInt(Math.round(v));
      const areaInKm2 = parseInt(roundedAreaValue / 1000000);

      return this.numberWithSpaces(areaInKm2);
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    /**
     * Initializes the country code selection list
     */
    initSelectAOILists() {
      this.countryCodeList = [];
      this.administrativeRegions.forEach(element => {
        let k = Object.keys(element)[0];
        this.countryCodeList.push({ code: k, name: k })
      });
    },
    clearSelectedCountry() {
      this.aoiForm.selectedCountryCode = {};
    },
    clearSelectedRegion() {
      this.aoiForm.selectedRegion = {}
    },
    showDisclaimerModal() {
      eventBus.$emit('show-modal-disclaimer');
    },

  },
  watch: {
    /**
     * Handles the tab-changed event
     */
    tabIndex(newTabIndex) {
      this.$store.commit("setDrawnAOIs", null);
      this.aoiSizeWarning = false;
      if (newTabIndex == 0) {
        // Remove the draw control buttons from the leaflet map object
        eventBus.$emit('removeDrawControl');
        this.$store.commit("setDrawnAOIs", null);
        this.clearCoordinates();
      } else if (newTabIndex == 1) {
        // Add the draw control buttons from the leaflet map object
        eventBus.$emit('addDrawControl');
        this.$store.commit("setAddedAOI", null);
      } else if (newTabIndex == 2) {
        this.$store.commit("setAddedAOI", null);
        eventBus.$emit('removeDrawControl');
        this.clearSelectedCountry();
        this.clearSelectedRegion();
      }
    },
    /**
     * Handles the card item selected event
     */
    aoiSelectionChanged() {
      this.stepCancelClicked();
      eventBus.$emit('removeDrawControl');
    },
    'aoiForm.selectedCountryCode'(v) {
      {
        if (v.code === undefined) {
          this.clearSelectedRegion();
          this.regionsList = [];
        } else {
          let regions = this.administrativeRegions.find(o => Object.keys(o)[0] === v.code);
          if (regions) {
            this.regionsList = [];
            regions[v.code].forEach(element => {
              this.regionsList.push({ code: element.id, name: element.state })
            });
          }
        }
      }
    },
    'aoiForm.selectedRegion'(v) {
      if (Object.keys(v).length === 2) {
        this.addedAOICount = 1;
        this.drawSelectedRegion(v.code)

      } else {
        this.$store.commit("setAddedAOI", null);
        this.addedAOICount = 0;
      }
    }
  }
};
</script>

<style scoped>
#disclaimer-info-btn:hover {
  cursor: pointer;
}

.form-label-bold {
  font-weight: bold;
}

.aoi-info-label {
  padding-left: 8px;
  font-style: italic;
}

.label-bold {
  font-weight: bold;
}

.checkbox-font {
  font-weight: normal;
}

.font-weight-validation {
  font-weight: normal;
}

/* Make circles that indicate the steps of the form */
.step {
  height: 12px;
  width: 12px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished */
.step.finish {
  background-color: #007bff;
}

.disabled-link {
  cursor: not-allowed;
  color: #6c757d;
}</style>
