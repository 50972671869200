<template>
  <div id="parent-container">
    <div id="popup-overlay" v-if="!cookiesAccepted">
      <div id="cookies" class="fixed-bott">
        <div class="bg-dark text-white rounded-lg" role="alert" data-autohide="false">
          <div class="toast-body p-4 d-flex flex-column">
            <h4>Cookies</h4>
            <p>
              This site uses cookies to offer you a better browsing experience. Find out more on <a href="https://ec.europa.eu/info/cookies_en" target="_blank"
                class="link-dark">how we use cookies</a>.
            </p>
            <div>
              <button @click="acceptCookies()" type="button" class="btn btn-light" id="btnAccept">
                Accept cookies
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="login" class="login container">
      <b-overlay :show="showOverlay" rounded="sm" variant="light" spinner-variant="secondary">
        <b-card class="card">
          <b-form>
            <div class="row">
              <div class="col pb-1">
                <b-img class="image" :src="require('./../assets/gfmlogo.png')" fluid alt="Responsive image"
                  width="100%"></b-img>
              </div>
            </div>
          </b-form>
          <h3 align="justify">
            <b data-cy="title-login">Login</b>
          </h3>
          <p class="p">Enter your GFM credentials to login</p>
          <b-alert v-model="showAlert" variant="danger" dismissible>{{ alertMsg }}</b-alert>
          <b-form @submit.prevent="onSubmit">
            <b-form-group class="font-bold" id="input-group-1" label="E-mail" label-for="input-1">
              <b-form-input id="input-1" v-model="form.email" type="email" required placeholder="Enter e-mail address"
                v-bind:class="{ pwalert: showAlert }" autocomplete="email" data-cy="input-email"></b-form-input>
            </b-form-group>
            <b-form-group class="font-bold" id="input-group-2" label="Password" label-for="input-2">
              <b-form-input id="input-2" v-model="form.password" type="password" placeholder="Enter password" required
                v-bind:class="{ pwalert: showAlert }" data-cy="input-password"></b-form-input>
            </b-form-group>
            <b-button :disabled="!cookiesAccepted" type="submit" variant="primary" block data-cy="submit-login">Login</b-button>
            <div class="mt-1"></div>
            <router-link to="/password-reset" tag="b-link" data-cy="forgot-password">Forgot Password?</router-link>
            <span>&nbsp;or&nbsp;</span>
            <router-link to="/register" tag="b-link" data-cy="register">Register</router-link>
          </b-form>

          <div class="d-flex mt-3 mb-3">
            <hr class="my-auto flex-grow-1 text-grey-ligh ">
            <div class="px-4 text-grey-ligh ">or</div>
            <hr class="my-auto flex-grow-1 text-grey-ligh ">
          </div>
          <b-button :disabled="!cookiesAccepted" block @click="glofasLogin()" data-cy="signin-glofas">Sign in with GloFAS</b-button>
          <b-button :disabled="!cookiesAccepted" block @click="efasLogin()" data-cy="signin-efas">Sign in with EFAS</b-button>
        </b-card>
      </b-overlay>
    </div>

    <div>
      <footer id="footer" style="background-color: #eff2f6">
        <div class="center">
          <div class="container">
            <div class="row">
              <div class="col text-center">
                <a href="https://www.globalfloods.eu/terms-of-service/" class="link-dark" target="_blank">Terms and
                  Conditions of Access</a>
              </div>
              <div class="col text-center">
                <a href="https://www.globalfloods.eu/static/downloads/Privacy_Statement_Copernicus_20230831.pdf"
                  class="link-dark" target="_blank">Data protection note</a>
              </div>
              <div class="col text-center">
                <a href="https://commission.europa.eu/cookies-policy_en" class="link-dark" target="_blank">EC cookies
                  policy</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import config from '@/config.js'

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      showAlert: false,
      alertMsg: "",
      showOverlay: false,
      cookiesAccepted: false,
    };
  },
  methods: {
    /* eslint-disable */
    onSubmit() {
      const formData = {
        email: this.form.email,
        password: this.form.password
      };
      this.showOverlay = true;
      this.$store.dispatch("signin", formData).then(
        response => {
          this.$store.commit("setUserType", config.GFM_USER);
          this.$store.commit("setViewVisibleForGlofas", true);
          this.$router.push(this.$route.query.redirect || "/");
          this.showOverlay = false;
          localStorage.setItem('login_src', "gfm");
        },
        error => {
          console.log("Error:", error);
          this.alertMsg = error.response.data.message.error_definition.message;
          this.showAlert = true;
          this.showOverlay = false;
        }
      );
    },
    /* eslint-enable */
    goBack() {
      this.$router.go(-1)
    },
    /**
     * Eventhandler for the glofas login button
     */
    glofasLogin() {
      localStorage.setItem('login_src', "glofas");
      this.$glofasAuth.login();
    },
    /**
     * Eventhandler for the efas login button
     */
    efasLogin() {
      localStorage.setItem('login_src', "efas");
      this.$efasAuth.login();
    },
    /**
     * Eventhandler to accept cookies
     */
    acceptCookies() {
      this.$cookies.set("_cookieGFMAccepted", "true", {expires: 7})
      this.cookiesAccepted = true;
    }
  },
  mounted() {
    this.form.email = "";

    let cookieSet = this.$cookies.get("_cookieGFMAccepted");
    if (!cookieSet) {
      this.cookiesAccepted = false;
    } else if (cookieSet == 'true'){
      this.cookiesAccepted = true;
    } else {
      this.cookiesAccepted = false;
    }

  }
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 40px;

}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

.text-grey-ligh {
  color: #BDC9D4;
  font-weight: bold;
}

.font-bold {
  font-weight: bold;
}

#footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 100px;
  z-index: 4;

}

#footer .center {
  margin: auto;
  width: 80%;
  padding: 35px;
  color: rgb(146, 146, 146);
}

#footer .link-dark {
  color: #9b9b9b;
}

#footer .link-dark:hover {
  color: #6b6b6b;
  text-decoration: none;
}


#footer .link-dark:active {
  color: #9b9b9b;
  text-decoration: none;
}

#parent-container {
  height: calc(100vh - 166px);
  overflow-y: auto;
}

#cookies {
  width: 45%;
  bottom: 190px;
  border-radius: 25px;
  position: absolute;
  right: 20px;

}

#popup-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(147, 147, 147, 0.5);
  z-index: 2;
}
</style>
