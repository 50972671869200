<template>
  <div>

    <b-modal id="modal-download-all-products" ref="modal" title="Download all products" ok-title="Start download"
      @ok="handleOkDownloadAll" :ok-disabled="disableOK" size="lg">
      <p>Please select the layers you wish to download.</p>
      <div class="mb-3">
        <b-card>
          <b-form-checkbox-group v-model="selectedLayers" :options="layerOptions" stacked></b-form-checkbox-group>
          <div class="pt-3">
            <b-link v-on:click="selectAllLayers">Select all layers</b-link> <br />
            <b-link v-on:click="removeAllLayers">Remove all layers</b-link>
          </div>
        </b-card>
      </div>
      <div v-if="filterOpts != null">
        <small>
          <div class="mb-1"><strong>Order summary</strong></div>
          <ul>
            <li><span style="display: block">AOI name: {{ getCurrentAoiName }}</span></li>
            <li><span style="display: block">Start date: {{ filterOpts.start }}</span></li>
            <li><span style="display: block">End date: {{ filterOpts.end }}</span></li>
          </ul>
        </small>
      </div>
      <span class="mt-3">As soon as the files have been prepared, you will be informed by mail.
        This process may take sometime.
      </span>
    </b-modal>

    <b-modal id="modal-download-max-extent" ref="modal" title="Generate maximum flood extent" ok-title="Generate extent"
      @ok="handleGenerateMaxExtent" :ok-disabled="selectedOutputTypes.length == 0" size="lg">
      <b-form-group label="Please choose the output type for the maximum flood extend product.">
        <b-form-checkbox-group v-model="selectedOutputTypes" :options="selectedOutputTypeOptions"
          stacked></b-form-checkbox-group>
      </b-form-group>
      <small>
        <div class="mb-1"><strong>Order summary</strong></div>
        <div>
          <ul v-if="filterOpts != null">
            <li><span style="display: block">AOI name: {{ getCurrentAoiName }}</span></li>
            <li><span style="display: block">Start date: {{ filterOpts.start }}</span></li>
            <li><span style="display: block">End date: {{ filterOpts.end }}</span></li>
          </ul>
        </div>
      </small>
      <span class="mt-3">As soon as the maximum flood extent product has been prepared, you will be informed by mail.
        This process may take sometime.
      </span>
    </b-modal>

    <div class="pull-right">
      <span id="popover-one-day">
        <b-button class="mb-1" variant="secondary" block size="sm" @click="downloadAllProductsClicked"
          :disabled="this.numberOfProducts == 1">
          Download all products (for user-selected layers) ...
        </b-button>
        <b-popover v-if="numberOfProducts == 1" target="popover-one-day" triggers="hover" placement="top">
          The download feature works only for more than one product.
        </b-popover>
      </span>
      <span id="popover-max-days">
        <b-button variant="secondary" block size="sm" @click="downloadMaxFloodExtentClicked"
          :disabled="!this.timeRangeCorrect || this.numberOfProducts == 1">
          Download maximum flood extent
        </b-button>
      </span>
      <b-popover v-if="!this.timeRangeCorrect || this.numberOfProducts == 1" target="popover-max-days" triggers="hover" placement="top">
        The maximum flood extent feature works only for:<br /><br />* More than one product<br />* Time ranges smaller
        than two months
      </b-popover>
    </div>

  </div>
</template>

<script>
import axios_services from '@/axios/axios-services.js'

export default {
  name: "DownloadAllProductsAndFloodExtent",
  components: {},
  props: {
    productMetaInformation: { type: Object, required: true },
    numberOfProducts: { type: Number, required: true },
  },
  data() {
    return {
      isHovered: false,

      selectedLayers: [],
      layerOptions: [
        { text: 'Observed Flood Extent (raster)', value: '1' },
        { text: 'Observed Flood Extent (vector)', value: '12' },
        { text: 'Observed Water Extent', value: '2' },
        { text: 'Reference Water Mask', value: '3' },
        { text: 'Exclusion Mask', value: '4' },
        { text: 'Likelihood Values', value: '5' },
        { text: 'Advisory Flags', value: '6' },
        { text: 'Sentinel-1 Metadata', value: '7' },
        { text: 'Sentinel-1 Footprint', value: '8' },
        { text: 'Sentinel-1 Schedule', value: '9' },
        { text: 'Affected Population', value: '10' },
        { text: 'Affected Landcover', value: '11' },
      ],

      selectedOutputTypes: [],
      selectedOutputTypeOptions: [
        { text: 'Raster', value: 'raster' },
        { text: 'Vector', value: 'vector' },
      ],
      filterOptsDateRangeCorrect: false
    };
  },
  computed: {
    clientID() {
      return this.$store.getters.clientID;
    },
    filterOpts() {
      return this.$store.getters.getFilterOpts;
    },
    disableOK() {
      return (this.selectedLayers.length == 0);
    },
    timeRangeCorrect() {
      return this.$store.getters.getTimeRangeCorrect;
    },
    getCurrentAoiName() {
      return this.$store.getters.getCurrentAoiName.name;
    }
  },
  mounted() {
  },
  methods: {
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true
      });
    },
    /**
     * Gets called when a user confirms the download all products dialog
     */
    handleOkDownloadAll() {
      const aoi_id = this.productMetaInformation.aoiID;

      var url_str = "";

      // Date filter
      if (this.filterOpts == null) {
        url_str = "/download/all_products/" + aoi_id + "/" + this.clientID + "?";
      } else {
        // Calculate end date
        var end_date = new Date(this.filterOpts.end);
        var end_date_plus_one = new Date(end_date);
        end_date_plus_one.setDate(end_date.getDate() + 1);
        let end_date_plus_one_str = end_date_plus_one.toISOString().slice(0, 10);

        url_str = "/download/all_products/" + aoi_id + "/" + this.clientID + "?" + "start_date=" + this.filterOpts.start + "&" + "end_date=" + end_date_plus_one_str;
      }

      // Layer filter
      if (this.selectedLayers.length != 12) { //TODO change to 12
        let filter_suffix = "&filter=" + this.selectedLayers.join()
        url_str = url_str + filter_suffix;
      }

      axios_services
        .get(url_str)
        .then(() => {
          // Enter code here
          this.showNotification(
            "success",
            "Success! Order received",
            "Download order successfully received"
          );
          this.$store.commit('setToggleProductsDownloadLoadingView', false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.showNotification(
            "danger",
            "Error!",
            "Download order could not be submitted."
          );
        })
    },
    /**
     * This function shows the download all products modal dilaog 
     */
    downloadAllProductsClicked() {
      this.$bvModal.show("modal-download-all-products");
      this.selectAllValue = 'accepted'
      this.selectedLayers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    },
    /**
     * Helper function to select all check boxes
     */
    selectAllLayers() {
      this.selectedLayers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    },
    /**
     * Helper function to remove all check boxes
     */
    removeAllLayers() {
      this.selectedLayers = [];
    },
    /**
     * Gets called when a user confirms the generate maximum flood extent dialog
     */
    handleGenerateMaxExtent() {
      let aoi_id = this.productMetaInformation.aoiID;
      let start_date = this.filterOpts.start;
      let end_date = this.filterOpts.end;

      let url_suffix;
      if (this.selectedOutputTypes.includes('raster') && this.selectedOutputTypes.includes('vector')) {
        url_suffix = "?output_type=both";
      } else if (this.selectedOutputTypes.includes('raster')) {
        url_suffix = "?output_type=raster";
      } else if (this.selectedOutputTypes.includes('vector')) {
        url_suffix = "?output_type=vector";
      }

      var url_str = "/download/max_flood_extent/" + aoi_id + "/" + start_date + "/" + end_date + url_suffix;

      axios_services
        .get(url_str)
        .then(() => {
          // Enter code here
          this.showNotification(
            "success",
            "Success! Order received",
            "Maximum flood extent order successfully received"
          );
        })
        .catch((error) => {
          console.log("Error: ", error);
          this.showNotification(
            "danger",
            "Error!",
            "Maximum flood extent order could not be submitted"
          );
        })
    },
    downloadMaxFloodExtentClicked() {
      this.$bvModal.show("modal-download-max-extent");
      this.selectedOutputTypes = ["raster", "vector"];
    }
  },
  watch: {}
};
</script>

<style scoped>
.download-icon:hover {
  cursor: pointer;
}
</style>
